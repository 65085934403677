<template>
    <div>
        <error-view
            image-path-in-assets="pigeon-mail.svg"
            :show-title="false"
            :description="$t('pages.CheckCardArrival.description')"
            :cta="$t('pages.CheckCardArrival.cta')"
            @click="cardArrived"
        >
            <base-button
                button-classes="btn btn-tertiary"
                @click="cardHaveNotArrived"
            >
                {{ $t('pages.CheckCardArrival.no_cta') }}
            </base-button>
        </error-view>
    </div>
</template>

<script>
    import ErrorView from '@/components/ErrorView'
    import BaseButton from '@/components/base/BaseButton'
    import { postLoginNavigation } from '@/utils/sharedLogic'

    export default {
        name: 'CardArrivalCheck',
        components: {
            ErrorView,
            BaseButton,
        },
        mounted: function () {
            this.$logEvent('view_card_arrival_check')
        },
        methods: {
            cardArrived: function () {
                this.$logEvent('click_button_card_arrived')
                this.$router.push({ name: 'CardActivation' })
            },
            cardHaveNotArrived: function () {
                this.$logEvent('click_button_card_not_arrived')
                postLoginNavigation()
            },
        },
    }
</script>

<style scoped></style>
